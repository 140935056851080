import { useEffect, useState } from 'react'
// MUI Core
import InputLabel from '@mui/material/InputLabel'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import DialogActions from '@mui/material/DialogActions'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import Box from '@mui/material/Box'

// Helpers, Hooks, Dictionaries
import useSWR from 'swr'
import { apiVercelFallback } from '@src/helpers/clientSide/fetch'
import { notificationPriorityTitles } from '@src/dictionaries/dictionaries'
import { baseUrl } from '@src/helpers/helper'
import { returnValueAsDate } from '@src/helpers/newHelper'

// styles
import notificationStyles from '../../src/styles/notifications.module.css'

// types
import type {
  UserGroup,
  User,
  UserFromToken,
  UserRole,
  Notification,
  NotificationRecipient
} from '@src/dictionaries/commonInterfaces'
import { type Breakpoint } from '@mui/system'
import { type AlertColor } from '@mui/material/Alert'

const thisFile = 'components/Notifications/CreateEditNotificationDialog ' // eslint-disable-line no-unused-vars

interface CreateEditNotificationDialogProps {
  displayId?: string
  users: User[]
  toggleModal?: (open: boolean, content?: string, width?: Breakpoint) => void
  showSnackbar?: (message: string, variant: AlertColor, duration?: number) => void
  fsdNotification?: boolean
  user?: UserFromToken
  cellClass?: (value: number) => string
  queryNotifications?: () => void
  notification?: Notification
  manageNotification?: boolean
}

const isRole = (role: string | UserRole): role is UserRole => {
  return (role as UserRole)?.name !== undefined
}

const CreateEditNotificationDialog = (props: CreateEditNotificationDialogProps) => {
  const {
    displayId,
    users,
    toggleModal = () => undefined,
    showSnackbar = () => undefined,
    fsdNotification = false,
    user,
    queryNotifications,
    notification
  } = props

  // state
  const [notificationRecipients, setNotificationRecipients] = useState<NotificationRecipient[] | User[]>(
    // @ts-expect-error TS(2345) FIXME: Argument of type 'NotificationRecipient[] | (User ... Remove this comment to see the full error message
    notification?.recipients || [users?.find((cUser) => cUser?.user_id === user.user_id)] || []
  )
  const [notificationMessage, setNotificationMessage] = useState<string>(notification?.message ?? '')
  const [notificationPostDate, setNotificationPostDate] = useState<Date>(notification?.postDate ?? new Date())
  const [notificationPriority, setNotificationPriority] = useState<number>(notification?.priority ?? 2)
  const [notificationLink, setNotificationLink] = useState<string>(notification?.link ?? '')
  const [notificationLinkType, setNotificationLinkType] = useState<string>(notification?.linkType ?? '')
  const [userGroupsToSend, setUserGroupsToSend] = useState<string[]>(notification?.userGroups ?? [])
  const [selectableUsers, setSelectableUsers] = useState<User[]>([])

  // validation

  /**
   * Checks if a required field is missing
   * @param field - The field to check if missing
   * @returns
   */
  const isValidField = (field: string) => {
    switch (field) {
      case 'message':
        return !notificationMessage
      case 'priority':
        return !notificationPriority
      case 'postDate':
        return !notificationPostDate
      case 'recipients':
        return !notificationRecipients?.length
      default:
        return false
    }
  }
  const requiredFields = ['message', 'postDate', 'priority', 'recipients']
  const missingFields = requiredFields.some((field) => isValidField(field))

  /**
   * Create a notification
   * @param notification - The notification to create
   */
  const handleNotification = async (isNew: boolean) => {
    try {
      const recipients = notificationRecipients.map((recipient) => {
        return {
          id: recipient._id,
          // @ts-expect-error TS(2339) FIXME: Property 'userId' does not exist on type 'User | N... Remove this comment to see the full error message
          userId: recipient.userId || user?.user_id,
          acknowledged: false,
          done: false,
          snooze: null
        }
      })
      const notificationToSend: Notification = {
        // @ts-expect-error TS(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
        id: isNew ? null : notification?.id,
        message: notificationMessage,
        priority: notificationPriority ?? 2,
        recipients,
        // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        sendingUserId: user?.user_id,
        postDate: notificationPostDate ?? new Date(),
        // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
        link: !fsdNotification ? `${notificationLink}` : isNew ? `${baseUrl}/opportunities/${displayId}` : null,
        // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
        linkType: !fsdNotification ? `${notificationLinkType}` : isNew ? 'opportunity' : null,
        userGroups: userGroupsToSend,
        updatedAt: new Date(),
        createdAt: notification?.createdAt ?? new Date()
      }
      const endpoint = isNew ? 'rest/notifications' : `rest/notifications/${notification?.id}`
      const method = isNew ? 'POST' : 'PATCH'
      await apiVercelFallback(endpoint, {
        method,
        body: JSON.stringify(notificationToSend)
      })
      const successMessage = isNew ? 'Notification created' : 'Notification updated'
      showSnackbar(successMessage, 'success')
      toggleModal(false)
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      queryNotifications()
    } catch (error) {
      console.error(`${thisFile}handleNotification error`, error)
      const errorMessage = isNew ? 'Error creating notification' : 'Error updating notification'
      showSnackbar(errorMessage, 'error')
    }
  }

  const { data: userGroupsData } = useSWR<{ userGroups: UserGroup[] } | null>('rest/userGroups', apiVercelFallback, {
    fallbackData: { userGroups: [] }
  })

  // effects
  useEffect(() => {
    const usersToSelect = users
      .filter(
        (user) =>
          !user.blocked &&
          user?.roles?.length &&
          (user.user_id.includes('@caseopp.com') ||
            user.user_id.includes('@reciprocityindustries.com') ||
            user.email.includes('@test.com') ||
            user.email.includes('puppetmaster'))
      )
      .sort((a, b) => {
        if (isRole(a.roles[0]) && isRole(b.roles[0])) {
          return a.roles[0]?.name.localeCompare(b.roles[0]?.name)
        } else if (isRole(a.roles[0]) && !isRole(b.roles[0])) {
          return a.roles[0]?.name.localeCompare(b.roles[0])
        } else if (!isRole(a.roles[0]) && isRole(b.roles[0])) {
          // @ts-expect-error TS(2532) FIXME: Property 'localeCompare' does not exist on type 'never'.
          return a.roles[0]?.localeCompare(b.roles[0]?.name)
        } else if (!isRole(a.roles[0]) && !isRole(b.roles[0])) {
          return a.name.localeCompare(b.name)
        } else {
          return 0
        }
      })
    setSelectableUsers(usersToSelect)
  }, [users])

  useEffect(() => {
    if (notification?.recipients?.length) {
      setNotificationRecipients(notification?.recipients)
    } else if (!notification?.recipients?.length) {
      // @ts-expect-error TS(2322) FIXME: Type 'User | undefined' is not assignable to type ... Remove this comment to see the full error message
      setNotificationRecipients([users?.find((cUser) => cUser?.user_id === user.user_id)])
    } else {
      setNotificationRecipients([])
    }
  }, [notification])

  return (
    <Box>
      {!fsdNotification && <DialogTitle>{notification ? 'Edit' : 'Create'} Notification</DialogTitle>}
      <DialogContent>
        <Grid container spacing={2} sx={{ pt: 1 }}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id='notificationPriorityLabel'>Priority</InputLabel>
              <Select
                id='notificationPriority'
                labelId='notificationPriorityLabel'
                label='Priority'
                defaultValue={notificationPriority}
                fullWidth
                className={notificationStyles[`priority_${notificationPriority}`]}
                onChange={(event) => {
                  setNotificationPriority(+event.target.value)
                }}
              >
                {Object.entries(notificationPriorityTitles).map(([key, value]) => {
                  return (
                    <MenuItem key={key} value={key} className={notificationStyles[`priority_${key}`]}>
                      {value}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {/** Autocomplete for notificationRecipients. Options of selectableUsers grouped by role, with order of caseManager, remoteIntake, supervisor, then all others */}
            <Autocomplete
              multiple
              id='notificationRecipients'
              options={selectableUsers}
              disableCloseOnSelect
              getOptionLabel={(option) => {
                let optionLabel = option?.name + ' - ' + option?.email
                // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                if (option?.name === option?.email) optionLabel = option?.name
                return optionLabel
              }}
              // @ts-expect-error TS(2322) FIXME: Type '(option: User | undefined) => string | undef... Remove this comment to see the full error message
              groupBy={(option) => (isRole(option?.roles?.[0]) ? option?.roles?.[0]?.name : option?.roles?.[0])}
              onChange={(_, value) => {
                const recipients = value?.map((recipient) => {
                  return {
                    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                    userId: recipient.user_id,
                    acknowledged: false,
                    done: false,
                    snooze: null
                  } satisfies NotificationRecipient
                })
                setNotificationRecipients(recipients)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Recipients'
                  error={!notificationRecipients?.length}
                  placeholder='Select recipients'
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  // @ts-expect-error TS(2783) FIXME: 'key' is specified more than once, so this usage w... Remove this comment to see the full error message
                  <Chip key={option?._id} label={option?.name} {...getTagProps({ index })} color='primary' />
                ))
              }
              defaultValue={
                !notification?.id
                  ? // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                    [users?.find((cUser) => cUser?.user_id === user.user_id)]
                  : users?.filter((user) =>
                      notification?.recipients?.some((recipient) => recipient?.userId === user?.user_id)
                    )
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id='userGroups'
              options={userGroupsData?.userGroups ?? []}
              disableCloseOnSelect
              getOptionLabel={(option: UserGroup) => option?.name}
              defaultValue={
                userGroupsData?.userGroups?.filter((userGroup) =>
                  notification?.userGroups?.some((userGroupToSend) => userGroupToSend === userGroup?.name)
                ) ?? []
              }
              onChange={(_, value) => {
                const userGroups = value?.map((userGroup) => userGroup?.name)
                setUserGroupsToSend(userGroups)
              }}
              renderInput={(params) => <TextField {...params} label='User Groups' placeholder='Select user groups' />}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  // @ts-expect-error TS(2783) FIXME: 'key' is specified more than once, so this usage w... Remove this comment to see the full error message
                  <Chip key={option._id} label={option.name} {...getTagProps({ index })} color='primary' />
                ))
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id='notificationMessage'
              label='Message'
              multiline
              error={!notificationMessage}
              value={notificationMessage}
              minRows={4}
              fullWidth
              onChange={(event) => {
                setNotificationMessage(event.target.value)
              }}
            />
          </Grid>
          {/* Don't display the download link */}
          <Grid item xs={12}>
            <TextField
              style={{ width: '100%' }}
              placeholder='Optional'
              value={fsdNotification ? `/opportunities/${displayId}` : notificationLink}
              id='notificationLink'
              label='Link'
              onChange={(event) => {
                setNotificationLink(event.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id='linkType'
              style={{ width: '100%' }}
              placeholder='Optional'
              label='Link Type'
              value={fsdNotification ? 'opportunity' : notificationLinkType}
              onChange={(event) => {
                setNotificationLinkType(event.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <DesktopDateTimePicker
              label='Post Date'
              format='MM/dd/yyyy hh:mm a'
              value={returnValueAsDate(notificationPostDate)}
              // @ts-expect-error TS(2345) FIXME: Argument of type 'Date | null' is not assignable t... Remove this comment to see the full error message
              onChange={(newValue) => setNotificationPostDate(newValue)}
              slotProps={{
                textField: {
                  helperText: 'Date and time to post notification to recipients'
                }
              }}
              disablePast
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {missingFields && (
          <Typography variant='body2' color='error' sx={{ mr: 2 }}>
            *Required fields missing
          </Typography>
        )}
        <Button
          color='primary'
          onClick={() => {
            toggleModal(false)
          }}
        >
          Cancel
        </Button>
        <Button
          color='primary'
          disabled={missingFields}
          onClick={() => {
            handleNotification(!notification?.id)
          }}
        >
          {!notification?.id ? 'Create' : 'Update'}
        </Button>
      </DialogActions>
    </Box>
  )
}

export default CreateEditNotificationDialog
