/* eslint-disable no-unused-vars */
import {
  consoleError,
  baseContext,
  getJsonWithErrorHandling,
  jsonPar,
  getHtmlTagContentById,
  consoleInfo
} from '../../src/helpers/helper'
import type { User } from '../../src/dictionaries/commonInterfaces'
import { apiVercelFallback } from '../../src/helpers/clientSide/fetch'
import safeAwait from 'safe-await'
import { isArray } from 'lodash'
const thisFile = 'src/helpers/userHelper.ts '

export type SelectableUser = Partial<User> & { isLabel?: boolean }

// valid domains for users
const validDomains = ['caseopp', 'reciprocityindustries', 'test', 'puppetmaster', 'avalaw']

/* eslint-enable no-unused-vars */
export const auth0ManagementClientConfig = {
  domain: process.env.AUTH0_MANAGEMENT_DOMAIN,
  clientId: process.env.AUTH0_MANAGEMENT_CLIENT_ID,
  clientSecret: process.env.AUTH0_MANAGEMENT_CLIENT_SECRET,
  scope: 'read:users update:users read:roles create:users'
}

/**
 * Calls the getUsers endpoint and sets state if provided.
 * @param setUsersCallback - `setUsers` state variable
 * @param setUsersLoadingCallback - `setUsersLoading` state variable
 */
export const getAllUsers = async (
  setUsersCallback: React.Dispatch<React.SetStateAction<User[]>>,
  setUsersLoadingCallback: React.Dispatch<React.SetStateAction<boolean>> | false = false
) => {
  const [errors, getUsersResult] = await safeAwait(getUsers())
  if (!errors && getUsersResult && setUsersCallback) setUsersCallback(getUsersResult)
  if (setUsersLoadingCallback) setUsersLoadingCallback(false)
}

/**
 * calls getUsers endpoint, returns only userGroups
 * @returns {Promise<Array>} userGroups
 */
export const getUserGroups = async () => {
  const [errors, getUsersResult] = await safeAwait(getUsers())
  if (!errors && getUsersResult) {
    const allGroups = []
    for (const user of getUsersResult) {
      if (user?.app_metadata?.groups?.length) allGroups.push(user.app_metadata.groups)
    }
    return [...new Set(allGroups.flat(2))]
  } else if (errors) {
    consoleError(thisFile, 'getUserGroups ERROR: ', errors)
    return []
  }
}

/**
 * Client Side helper that fetches all users via the api/getUsers endpoint
 * @returns users
 */
export const getUsers = async () => {
  try {
    const usersFetch = await apiVercelFallback('/getUsers', {
      // eslint-disable-line no-undef
      method: 'GET'
    })
    let errorDetails
    const parsedBody = getJsonWithErrorHandling(await JSON.stringify(usersFetch), { throwError: false })
    if (parsedBody.error && parsedBody.text?.substr && parsedBody.text.substr(0, 15) === '<!DOCTYPE html>') {
      // Next Error
      const nextData = jsonPar(getHtmlTagContentById(parsedBody.text, 'script', '__NEXT_DATA__'))
      consoleInfo(thisFile, 'createOppAPI nextData:', nextData)
      errorDetails = nextData?.err?.stack || parsedBody.text
      consoleError(thisFile, 'errorDetails:', errorDetails)
      throw new Error(parsedBody.error)
    } else if (parsedBody.error) {
      consoleError(thisFile, 'parsedBody.error:', parsedBody)
      throw new Error(parsedBody.error)
    } else {
      const formattedUsers = []
      for (const user of parsedBody) {
        user.permissions = user.directlyAssignedPermissions
          ? [...user.permissions, ...user.directlyAssignedPermissions]
          : user.permissions
        formattedUsers.push(user)
      }
      return formattedUsers
    }
  } catch (error) {
    consoleError(thisFile, 'getUsers Error:', (error as Error).message)
  }
  return []
}

/**
 * Sorts users by specified group and filters based on activity status, blocked status, and organizational inclusion.
 * @param usersData - Array of users
 * @param hideBlocked - Flag to hide blocked users
 * @param hideOtherUsers - Flag to hide users from outside organizations
 * @param includedUsers - Array of user IDs to ensure inclusion
 * @param groupBy - User field to group users by can be `role` or `group` or in special cases the `permissions`. If not provided, all non blocked, internal users are included.
 * @returns Array of users with sorted and grouped structure
 */
export const sortUsers = (
  usersData: User[],
  groupBy?: string,
  hideBlocked = true,
  hideOtherUsers = true,
  includedUsers: string | string[] = []
): SelectableUser[] => {
  let usersGroups
  if (typeof groupBy === 'string' && groupBy.includes(',')) {
    usersGroups = groupBy.split(',')
  } else {
    usersGroups = [groupBy]
  }
  const usersToBeSorted =
    baseContext === 'pro' ? usersData.filter((user) => !user?.email?.includes('@test')) : usersData
  usersToBeSorted.filter((user, index, self) => index === self.findIndex((t) => t.email === user.email))
  usersToBeSorted.sort((a: User, b: User) => a?.email?.localeCompare(b?.email))
  const matchesGroupBy = (user: User) =>
    groupBy
      ? usersGroups.some((group) => {
          return (
            group &&
            (hasUserRole(user, group) ||
              user?.app_metadata?.groups?.includes(group) ||
              user?.permissions?.includes(group))
          )
        })
      : true
  const categorizeUser = (user: User, categories: { active: User[]; inactive: User[]; other: User[] }) => {
    const categorizedUser = matchesGroupBy(user)
    if (categorizedUser) {
      user?.blocked ? categories?.inactive?.push(user) : categories?.active?.push(user)
    } else {
      categories.other.push(user)
    }
  }
  const categories = { active: [] as User[], inactive: [] as User[], other: [] as User[] }
  usersToBeSorted.forEach((user) => categorizeUser(user, categories))

  const createGroupedUsers = () => {
    const labelName = groupBy || 'All Users'
    const groupedUsers = [
      { name: `${labelName}`, isLabel: true, users: categories.active },
      ...(hideBlocked ? [] : [{ name: `${labelName} - Inactive Users`, isLabel: true, users: categories.inactive }]),
      ...(hideOtherUsers ? [] : [{ name: `${labelName} - Other`, isLabel: true, users: categories.other }])
    ]

    const sortedUsers: SelectableUser[] = []
    groupedUsers.forEach(({ name, isLabel, users }) => {
      if (isLabel) sortedUsers.push({ name, isLabel: true }) // Add label as non-selectable divider
      users.forEach((user) => {
        if (shouldIncludeUser(user, name)) sortedUsers.push(user)
      })
    })

    includeAdditionalUsers(sortedUsers, usersData, includedUsers)
    return sortedUsers
  }
  const shouldIncludeUser = (user: User, category: string) => {
    if (hideBlocked && user.blocked) return false
    if (hideOtherUsers && category.includes('Other')) return false
    return true
  }
  const includeAdditionalUsers = (sortedUsers: SelectableUser[], allUsers: User[], includeList: string | string[]) => {
    if (!isArray(includeList)) {
      includeList = [includeList]
    }

    let labelAdded = false
    includeList.forEach((userId) => {
      if (!sortedUsers.some((user) => 'user_id' in user && user.user_id === userId)) {
        const user = allUsers.find((u) => u.user_id === userId)
        if (user) {
          if (!labelAdded) {
            sortedUsers.push({ name: 'Opp Selected Users', isLabel: true })
            labelAdded = true
          }
          sortedUsers.push(user)
        }
      }
    })
  }

  return createGroupedUsers()
}

/**
 * used to determine if user has the role of roleNameToCheck
 * @param {Object} user
 * @param {String} roleNameToCheck
 * @returns Boolean
 */
export function hasUserRole(user: User, roleNameToCheck: string) {
  if (!user?.roles?.length) return false
  for (const role of user.roles) {
    if (role.name === roleNameToCheck) return true
  }
  return false
}

/**
 * Renders the user name based on the selected value (user_id) and the users array
 *
 * @param selectedValue - The selected value(s), which can be a string or an array of strings (user_id).
 * @param users - The array of users to search through.
 * @returns The user name email /names and emails, if found, otherwise the selected value(s).
 */
export const renderUserName = (selectedValue: string | string[], users: SelectableUser[]): string => {
  if (Array.isArray(selectedValue)) {
    return selectedValue
      .map((userId) => {
        const user = users.find((user) => user.user_id === userId)
        return user ? `${user.name} (${user.email})` : userId
      })
      .join(', ')
  }

  // For a single selected user_id, find the corresponding user
  const user = users.find((user) => user.user_id === selectedValue)
  return user ? `${user.name} (${user.email})` : selectedValue
}

module.exports = {
  auth0ManagementClientConfig,
  getUsers,
  getAllUsers,
  getUserGroups,
  sortUsers,
  hasUserRole,
  renderUserName
}
