import { type QuestionAnswerType } from './commonInterfaces'

export const QUESTION_TYPE: Record<
  | 'SELECT_ONE'
  | 'SELECT_MANY'
  | 'TEXT'
  | 'BOOL'
  | 'NUMBER'
  | 'DATE'
  | 'DATETIME'
  | 'SECRET'
  | 'NO_ANSWER'
  | 'REPEATING_QUESTION',
  QuestionAnswerType
> = {
  SELECT_ONE: 'Select One',
  SELECT_MANY: 'Select Many',
  TEXT: 'Text Field',
  BOOL: 'Boolean',
  NUMBER: 'Number',
  DATE: 'Date',
  DATETIME: 'DateTime',
  SECRET: 'Secret',
  NO_ANSWER: 'No Answer',
  REPEATING_QUESTION: 'Repeating Question'
}
